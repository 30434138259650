import { motion } from "framer-motion";
import { useState } from "react";
import { useContext, forwardRef } from "react";
import ScreenWidthContext from "../ScreenWidthContext";

const Mailicon = forwardRef((props, ref) => {
  const { screenWidth } = useContext(ScreenWidthContext);
  const [hover, setHover] = useState(true);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{
        backgroundColor: props.backgroundVisible ? "white" : "transparent",
        position: "absolute",
        bottom: screenWidth <= 900 ? "134px" : "43.63px",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 5,
      }}
    >
      <a
        href="mailto:contact@onu.live"
        onTapStart={() => {
          setHover(true);
        }}
        onTapCancel={() => {
          setHover(false);
        }}
        onTapEnd={() => {
          setHover(false);
        }}
        onMouseEnter={() => {
          setHover(false);
        }}
        onMouseLeave={() => {
          setHover(true);
        }}
      >
        {hover ? (
          <svg
            ref={ref}
            xmlns="http://www.w3.org/2000/svg"
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            style={{
              stroke: "black",
              width: screenWidth <= 400 ? "5.385em" : "3.385em",
              height: screenWidth <= 400 ? "5.385em" : "3.385em",
              /* width: "3.385em", */
              /* height: "3.385em", */
            }}
          >
            <circle cx="27.0467" cy="26.5909" r="25" strokeWidth="3" />
            <path
              d="M15.9364 18.5815V34.6003M15.9364 18.5815H22.4599H31.6335H38.157M15.9364 18.5815L23.7491 26.6275M15.9364 34.6003H22.4599H31.6335H38.157M15.9364 34.6003L23.7491 26.6275M38.157 34.6003V18.5815M38.157 34.6003L30.3443 26.6275M38.157 18.5815L30.3443 26.6275M23.7491 26.6275L25.3642 28.2908C26.2853 29.2394 27.8081 29.2394 28.7292 28.2908L30.3443 26.6275"
              strokeWidth="2.48822"
            />
          </svg>
        ) : (
          <svg
            ref={ref}
            width="57"
            height="57"
            viewBox="0 0 57 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              stroke: "black",
              width: screenWidth <= 400 ? "5.385em" : "3.385em",
              height: screenWidth <= 400 ? "5.385em" : "3.385em",
              /* width: "3.385em", */
              /* height: "3.385em", */
            }}
          >
            <path
              d="M17.3897 20.4906V36.5094M17.3897 20.4906L26.841 11.0392C27.7573 10.123 29.2427 10.123 30.159 11.0392L39.6103 20.4906M17.3897 20.4906L21.3544 24.4553M17.3897 36.5094L25.2024 28.5366L25.319 28.4199M17.3897 36.5094H39.6103M39.6103 36.5094V20.4906M39.6103 36.5094L31.7976 28.5366L31.681 28.4199M39.6103 20.4906L35.5873 24.5136M31.681 28.4199L28.5 25.239L25.319 28.4199M31.681 28.4199L35.5873 24.5136M31.681 28.4199L31.5643 28.5366M25.319 28.4199L21.3544 24.4553M21.3544 24.4553V18.5119H35.5873V24.5136M55 28.5C55 43.1355 43.1355 55 28.5 55C13.8645 55 2 43.1355 2 28.5C2 13.8645 13.8645 2 28.5 2C43.1355 2 55 13.8645 55 28.5Z"
              stroke="black"
              stroke-width="2.49"
            />
          </svg>
        )}
      </a>
    </motion.div>
  );
});

export default Mailicon;
