import { createContext, useState } from "react";

const AnimationContext = createContext();

export function AnimationProvider({ children }) {
  const [shouldAnimate, setShouldAnimate] = useState(true);
  return (
    <AnimationContext.Provider value={{ shouldAnimate, setShouldAnimate }}>
      {children}
    </AnimationContext.Provider>
  );
}

export default AnimationContext;
