import { useState, useEffect, useRef, useContext } from "react";
import { motion } from "framer-motion";

import RectText from "./RectText";
import ScreenWidthContext from "../ScreenWidthContext";

const Slide2 = () => {
  const [rects, setRects] = useState([]);
  const [mouseCoordinate, setMouseCoordinate] = useState({ x: 0, y: 0 });
  const rootDivRef = useRef();

  const { screenWidth } = useContext(ScreenWidthContext);

  const mouseMoveEvent = (event) => {
    setMouseCoordinate({
      x: event.clientX.toFixed(2),
      y: event.clientY.toFixed(2),
    });
  };

  const touchMoveEvent = (event) => {
    const touch = event.touches[0];
    setMouseCoordinate({
      x: touch.pageX.toFixed(2),
      y: touch.pageY.toFixed(2),
    });
  };

  const addEventListner = () => {
    const aniDiv = document.getElementById("animated-text-container");
    aniDiv.addEventListener("mousemove", mouseMoveEvent);
    aniDiv.addEventListener("touchmove", touchMoveEvent);
  };

  useEffect(() => {
    const rects = document.querySelectorAll("[id='ONU-Rect']");
    setRects(rects);
    const aniDiv = document.getElementById("animated-text-container");

    return () => {
      aniDiv.removeEventListener("mousemove", mouseMoveEvent);
      aniDiv.removeEventListener("touchmove", touchMoveEvent);
    };
  }, []);
  return (
    <motion.div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
      ref={rootDivRef}
      initial={{ opacity: 0 }}
      animate={{
        opacity: [1, 0, 1, 0, 1, 0, 1, 1],
      }}
      transition={{
        /* times: [0, 0.1, 0.3, 0.4], */
        times: [0.0, 0.01, 0.04, 0.08, 0.12, 0.2, 0.28, 0.32],
        duration: 0.32,
        ease: "linear",
      }}
    >
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "50%",
            transform: "translate(-50%, -303%)",
            width: "auto",
            height: "auto",
            fontFamily: "Inter",
            color: "white",
            fontSize: screenWidth <= 900 ? "0.7.7em" : "0.636875em",
          }}
        >
          <p>
            <span>{mouseCoordinate.x}</span>
            <span>, </span>
            <span>{mouseCoordinate.y}</span>
          </p>
        </div>
        <motion.svg
          width="663"
          height="268"
          viewBox="0 0 663 268"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            width: "41.4375em",
            height: "19.75em",
          }}
        >
          <g id="ONU LOGO Slide 2">
            <g id="ONU LOGO Final">
              <g id="O">
                <g id="Bottom_Left">
                  <path
                    id="Vector"
                    d="M131.185 262.327C106.765 262.327 84.612 256.804 65.1366 245.743C45.8809 234.909 30.0652 218.994 19.4637 199.784C8.48789 180.175 3 158.298 3 133.641"
                    stroke="white"
                    strokeWidth="0.78533"
                  />
                  <path
                    id="Vector_2"
                    d="M131.185 257.348V257.333C51.1257 257.333 53.8425 200.198 53.8425 133.641"
                    stroke="white"
                    strokeWidth="0.78533"
                  />
                </g>
                <g id="Top_Left">
                  <path
                    id="Vector_3"
                    d="M3 133.641C3 108.983 8.48789 86.8079 19.4637 67.5356C30.0623 48.3237 45.8787 32.408 65.1366 21.5761C84.612 10.5306 106.765 5.00012 131.178 5.00012"
                    stroke="white"
                    strokeWidth="0.78533"
                  />
                  <path
                    id="Vector_4"
                    d="M131.185 9.979C53.6718 9.979 53.8425 67.0836 53.8425 133.641"
                    stroke="white"
                    strokeWidth="0.78533"
                  />
                </g>
                <g id="Bottom_Right">
                  <path
                    id="Vector_5"
                    d="M131.185 262.327C155.916 262.327 177.751 256.789 197.226 245.728C216.39 234.981 232.192 219.244 242.907 200.236C253.875 180.649 259.363 158.612 259.363 133.656"
                    stroke="white"
                    strokeWidth="0.78533"
                  />
                  <path
                    id="Vector_6"
                    d="M208.52 133.671C208.52 200.251 211.237 257.348 131.185 257.348V257.333"
                    stroke="white"
                    strokeWidth="0.78533"
                  />
                </g>
                <g id="Top_Right">
                  <path
                    id="Vector_7"
                    d="M131.178 5.00012C155.869 5.00012 177.751 10.5306 197.226 21.5914C216.529 32.1582 232.376 47.9399 242.907 67.0837C253.875 86.6701 259.363 109.014 259.363 133.656"
                    stroke="white"
                    strokeWidth="0.78533"
                  />
                  <path
                    id="Vector_8"
                    d="M208.52 133.671C208.52 67.0913 208.699 9.979 131.185 9.979"
                    stroke="white"
                    strokeWidth="0.78533"
                  />
                </g>
              </g>
              <g id="Group 202">
                <g id="U">
                  <g id="U Outer">
                    <path
                      id="Vector_9"
                      d="M440.79 133.641C440.79 210.752 475.073 262.328 549.174 262.328C572.073 262.328 657.55 262.328 657.55 166.426V5H655.028"
                      stroke="white"
                      strokeWidth="0.78533"
                    />
                  </g>
                  <g id="U Inner">
                    <path
                      id="Vector_10"
                      d="M491.641 133.687C491.641 166.426 491.641 229.153 572.073 229.153C652.505 229.153 652.505 144.465 652.505 133.687V5H655.028"
                      stroke="white"
                      strokeWidth="0.78533"
                    />
                  </g>
                </g>
                <g id="N">
                  <g id="Bottom_Left_N">
                    <g id="N Innter">
                      <path
                        id="Vector_11"
                        d="M440.79 133.641C440.79 84.5411 431.165 38.191 360.366 38.191C279.934 38.191 279.934 122.917 279.934 133.641V262.328H277.411"
                        stroke="white"
                        strokeWidth="0.78533"
                      />
                    </g>
                    <g id="N Outer">
                      <path
                        id="Vector_12"
                        d="M491.641 133.687C491.641 54.8792 458.48 5.00061 383.265 5.00061C360.366 5.00061 274.889 5.00062 274.889 100.887V262.328H277.411"
                        stroke="white"
                        strokeWidth="0.78533"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <motion.g
              id="ONU-Rects"
              initial={{ opacity: 0 }}
              animate={{ opacity: [0, 1, 1, 1, 1, 0, 1, 1, 0, 1] }}
              transition={{ delay: 0.4, duration: 0.3 }}
            >
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1389"
                  x="648.436"
                  y="3.43571"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1390"
                  x="654.436"
                  y="3.43571"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1398"
                  x="566.799"
                  y="224.932"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1397"
                  x="543.962"
                  y="258.9"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1396"
                  x="487.413"
                  y="129.668"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1395"
                  x="435.866"
                  y="129.669"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1385"
                  x="-0.435714"
                  y="0.435714"
                  width="7.58666"
                  height="7.66457"
                  transform="matrix(-1 0 0 1 386.587 2)"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1386"
                  x="-0.435714"
                  y="0.435714"
                  width="7.58666"
                  height="7.66457"
                  transform="matrix(-1 0 0 1 363.413 35)"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1392"
                  x="277.466"
                  y="259.436"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1391"
                  x="271.936"
                  y="259.436"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1394"
                  x="276.114"
                  y="129.668"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1393"
                  x="271.133"
                  y="96.8529"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1378"
                  x="255.682"
                  y="129.669"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1383"
                  x="-0.435714"
                  y="0.435714"
                  width="7.58666"
                  height="7.66457"
                  transform="matrix(-1 0 0 1 246.638 62.8347)"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1388"
                  x="239.486"
                  y="195.738"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1377"
                  x="204.884"
                  y="129.669"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1384"
                  x="-0.435714"
                  y="0.435714"
                  width="7.58666"
                  height="7.66457"
                  transform="matrix(-1 0 0 1 200.964 16.3002)"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1387"
                  x="193.812"
                  y="242.272"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1375"
                  x="128.109"
                  y="0.435714"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1376"
                  x="128.109"
                  y="5.59391"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1385_2"
                  x="128.109"
                  y="253.443"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1386_2"
                  x="128.109"
                  y="258.601"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="NOT-ONU-Rect"
                  x="62.5315"
                  y="16.7352"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1383_2"
                  x="-0.435714"
                  y="0.435714"
                  width="7.58666"
                  height="7.66457"
                  transform="matrix(-1 0 0 1 69.6821 241.837)"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1380"
                  x="51.2337"
                  y="129.667"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1381"
                  x="16.8572"
                  y="63.2692"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1384_2"
                  x="-0.435714"
                  y="0.435714"
                  width="7.58666"
                  height="7.66457"
                  transform="matrix(-1 0 0 1 24.008 195.303)"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
              <g id="ONU-Rect">
                <rect
                  id="Rectangle 1379"
                  x="0.435714"
                  y="129.667"
                  width="7.58666"
                  height="7.66457"
                  stroke="white"
                  strokeWidth="0.871429"
                />
              </g>
            </motion.g>
          </g>
        </motion.svg>
      </div>
      {Array.from(rects).map((rect, index) => {
        return (
          <RectText
            key={index}
            top={
              rect.getBoundingClientRect().top -
              rootDivRef.current.getBoundingClientRect().top
            }
            left={
              rect.getBoundingClientRect().left -
              rootDivRef.current.getBoundingClientRect().left
            }
            index={index}
            text={{
              top: rect.getBoundingClientRect().top,
              left: rect.getBoundingClientRect().left,
            }}
            func={addEventListner}
          />
        );
      })}
    </motion.div>
  );
};

export default Slide2;
