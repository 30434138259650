import { motion, useMotionValue, animate, useTransform } from "framer-motion";
import { useEffect, useContext, memo } from "react";
import ScreenWidthContext from "../ScreenWidthContext";

const RectText = ({ top, left, index, text, func }) => {
  let delay = 0.38 + Math.random() * (0.2 - 0.0) + 0.2;
  delay =
    index <= 9 && index > 2
      ? delay - 0.2
      : index <= 27 && index >= 15
      ? delay + Math.random() * (0.3 - 0.05) + 0.3
      : delay;

  const TextAnimDuration = 0.5;

  const { screenWidth } = useContext(ScreenWidthContext);

  const t = (v) => v.toFixed(2);
  const topMotionValue = useMotionValue(0);
  const leftMotionValue = useMotionValue(0);
  const topTruncated = useTransform(topMotionValue, t);
  const leftTruncated = useTransform(leftMotionValue, t);

  useEffect(() => {
    const topValueAnimation = animate(topMotionValue, text.top, {
      duration: TextAnimDuration,
      delay: delay + 0.1,
      ease: "linear",
      onComplete: (d) => (index === 23 ? func() : null),
    });
    const leftValueAnimation = animate(leftMotionValue, text.left, {
      duration: TextAnimDuration,
      delay: delay + 0.1,
      ease: "linear",
    });

    return () => {
      topValueAnimation.stop();
      leftValueAnimation.stop();
    };
  }, [top, left]);

  const offset = { x: 0, y: 0 };
  switch (index) {
    case 27:
      offset.x = screenWidth * (3.55 / 100);
      offset.y = screenWidth * (0.3125 / 100);
      break;
    case 26:
      offset.x = screenWidth * (2.0833333333 / 100);
      offset.y = screenWidth * (0.5208333333 / 100);
      break;
    case 25:
      offset.x = screenWidth * (2.0833333333 / 100);
      offset.y = -screenWidth * (0.625 / 100);
      break;
    case 24:
      offset.x = screenWidth * (0.3645833333 / 100);
      offset.y = screenWidth * (0.625 / 100);
      break;
    case 23:
      offset.x = screenWidth * (3.6979166667 / 100);
      offset.y = -screenWidth * (0.0520833333 / 100);
      break;
    case 22:
      offset.x = screenWidth * (2.34375 / 100);
      offset.y = -screenWidth * (0.7291666667 / 100);
      break;
    case 21:
      offset.x = screenWidth * (2.34375 / 100);
      offset.y = -screenWidth * (0.7291 / 100);
      break;
    case 20:
      offset.x = screenWidth * (0.6770833333 / 100);
      offset.y = screenWidth * (0.7291 / 100);
      break;
    case 19:
      offset.x = screenWidth * (1.8229166667 / 100);
      offset.y = screenWidth * (0.7291 / 100);
      break;
    case 18:
      offset.x = screenWidth * (0.2604166667 / 100);
      offset.y = -screenWidth * (1.1979166667 / 100);
      break;
    case 17:
      offset.x = 0;
      offset.y = -screenWidth * (0.7291 / 100);
      break;
    case 16:
      offset.x = 0;
      offset.y = screenWidth * (0.7291 / 100);
      break;
    case 15:
      offset.x = 0;
      offset.y = screenWidth * (0.7291 / 100);
      break;
    case 14:
      offset.x = 0;
      offset.y = screenWidth * (0.7291 / 100);
      break;
    case 13:
      offset.x = 0;
      offset.y = screenWidth * (0.7291 / 100);
      break;
    case 12:
      offset.x = 0;
      offset.y = 0;
      break;
    case 11:
      offset.x = 0;
      offset.y = screenWidth * (0.5208333333 / 100);
      break;
    case 10:
      offset.x = 0;
      offset.y = 0;
      break;
    case 9:
      offset.x = 0;
      offset.y = -screenWidth * (0.78125 / 100);
      break;
    case 8:
      offset.x = -screenWidth * (0.2083333333 / 100);
      offset.y = screenWidth * (0.677083 / 100);
      break;
    case 7:
      offset.x = -screenWidth * (0.0520833333 / 100);
      offset.y = -screenWidth * (0.78125 / 100);
      break;
    case 6:
      offset.x = screenWidth * (2.5 / 100);
      offset.y = screenWidth * (0.8854166667 / 100);
      break;
    case 5:
      offset.x = 0;
      offset.y = 0;
      break;
    case 4:
      offset.x = 0;
      offset.y = 0;
      break;
    case 3:
      offset.x = screenWidth * (2.6041666667 / 100);
      offset.y = -screenWidth * (1.0416666667 / 100);
      break;
    case 2:
      offset.x = 0;
      offset.y = screenWidth * (0.9375 / 100);
      break;
    case 1:
      offset.x = -screenWidth * (0.2604166667 / 100);
      offset.y = screenWidth * (1.0416666667 / 100);
      break;
    case 0:
      offset.x = screenWidth * (3.5416666667 / 100);
      offset.y = -screenWidth * (0.7291666667 / 100);
      break;
    default:
      break;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.1,
        delay: delay,
      }}
      style={{
        width: "100%",
        position: "absolute",
        top: `${top - offset.y * (screenWidth <= 900 ? 2.4 : 1)}px`,
        left: `${left - offset.x * (screenWidth <= 900 ? 1.4 : 1)}px`,
        color: "white",
        fontSize: screenWidth <= 900 ? "0.79em" : "0.636875em",
        fontStyle: "Inter",
        fontWeight: "400",
      }}
    >
      <p>
        <motion.span>{leftTruncated}</motion.span>
        <span>, </span>
        <motion.span>{topTruncated}</motion.span>
      </p>
    </motion.div>
  );
};

export default memo(RectText);
