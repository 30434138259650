import { motion, MotionConfig } from "framer-motion";
import { useContext } from "react";

import AnimationContext from "../AnimationContext.js";

const Slide1 = () => {
  const logoAnimDelay = 1.5;
  const { shouldAnimate, setShouldAnimate } = useContext(AnimationContext);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MotionConfig
        transition={{
          duration: shouldAnimate ? 0.4 : 0.0,
          delay: shouldAnimate ? logoAnimDelay : 0.0,
          ease: "linear",
        }}
      >
        <motion.svg
          width="656"
          height="260"
          viewBox="0 0 656 260"
          fill="none"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            width: "41em",
            height: "16.25em",
          }}
        >
          <g id="ONU Logo">
            <g id="O">
              <g id="Bottom_Left">
                <motion.path
                  id="Vector"
                  d="M128.91 258.663C104.49 258.663 82.3371 253.14 62.8617 242.079C43.606 231.245 27.7903 215.33 17.1888 196.12C6.21299 176.511 0.725098 154.634 0.725098 129.977"
                  stroke="black"
                  strokeWidth="0.78533"
                  style={{
                    strokeDasharray: "203",
                  }}
                  initial={{ strokeDashoffset: 203 }}
                  animate={{ strokeDashoffset: 0 }}
                  onAnimationComplete={(d) => {
                    setShouldAnimate(false);
                  }}
                />
                <motion.path
                  id="Vector_2"
                  d="M128.91 253.684V253.669C48.8508 253.669 51.5676 196.534 51.5676 129.977"
                  stroke="black"
                  strokeWidth="0.78533"
                  style={{ strokeDasharray: "170" }}
                  initial={{ strokeDashoffset: 170 }}
                  animate={{ strokeDashoffset: 0 }}
                />
                />
              </g>
              <motion.g id="Top_Left">
                <motion.path
                  id="Vector_3"
                  d="M0.725098 129.977C0.725098 105.319 6.21299 83.144 17.1888 63.8717C27.7874 44.6598 43.6038 28.7441 62.8617 17.9122C82.3371 6.86664 104.49 1.33618 128.903 1.33618"
                  stroke="black"
                  strokeWidth="0.78533"
                  style={{
                    strokeDasharray: "203",
                  }}
                  initial={{ strokeDashoffset: 203 }}
                  animate={{ strokeDashoffset: 406 }}
                />
                <motion.path
                  id="Vector_4"
                  d="M128.91 6.31506C51.3969 6.31506 51.5676 63.4197 51.5676 129.977"
                  stroke="black"
                  strokeWidth="0.78533"
                  style={{ strokeDasharray: 170 }}
                  initial={{ strokeDashoffset: 170 }}
                  animate={{ strokeDashoffset: 0 }}
                />
              </motion.g>
              <g id="Bottom_Right">
                <motion.path
                  id="Vector_5"
                  d="M128.91 258.663C153.641 258.663 175.476 253.125 194.951 242.064C214.115 231.317 229.917 215.58 240.632 196.572C251.6 176.985 257.088 154.948 257.088 129.992"
                  stroke="black"
                  strokeWidth="0.78533"
                  style={{
                    strokeDasharray: "203",
                  }}
                  initial={{ strokeDashoffset: 203 }}
                  animate={{ strokeDashoffset: 0 }}
                />
                <motion.path
                  id="Vector_6"
                  d="M206.245 130.007C206.245 196.587 208.962 253.684 128.91 253.684V253.669"
                  stroke="black"
                  strokeWidth="0.78533"
                  style={{ strokeDasharray: 171 }}
                  initial={{ strokeDashoffset: 171 }}
                  animate={{ strokeDashoffset: 342 }}
                />
                />
              </g>
              <g id="Top_Right">
                <motion.path
                  id="Vector_7"
                  d="M128.903 1.33618C153.594 1.33618 175.476 6.86664 194.951 17.9275C214.254 28.4943 230.101 44.276 240.632 63.4198C251.6 83.0062 257.088 105.35 257.088 129.992"
                  stroke="black"
                  strokeWidth="0.78533"
                  style={{ strokeDasharray: 203 }}
                  initial={{ strokeDashoffset: 203 }}
                  animate={{ strokeDashoffset: 0 }}
                />
                />
                <motion.path
                  id="Vector_8"
                  d="M206.245 130.007C206.245 63.4274 206.424 6.31506 128.91 6.31506"
                  stroke="black"
                  strokeWidth="0.78533"
                  style={{ strokeDasharray: 170 }}
                  initial={{ strokeDashoffset: 170 }}
                  animate={{ strokeDashoffset: 340 }}
                />
                />
              </g>
            </g>
            <g id="Group 202">
              <g id="U">
                <g id="U Outer">
                  <motion.path
                    id="Vector_9"
                    d="M438.515 129.977C438.515 207.088 472.798 258.664 546.899 258.664C569.798 258.664 655.275 258.664 655.275 162.762V1.33606H652.753"
                    stroke="black"
                    strokeWidth="0.78533"
                    style={{ strokeDasharray: 521 }}
                    initial={{ strokeDashoffset: 521 }}
                    animate={{ strokeDashoffset: 521 * 2 }}
                  />
                </g>
                <g id="U Inner">
                  <motion.path
                    id="Vector_10"
                    d="M489.366 130.023C489.366 162.762 489.366 225.489 569.798 225.489C650.23 225.489 650.23 140.801 650.23 130.023V1.33606H652.753"
                    stroke="black"
                    strokeWidth="0.78533"
                    style={{ strokeDasharray: 417 }}
                    initial={{ strokeDashoffset: 417 }}
                    animate={{ strokeDashoffset: 417 * 2 }}
                  />
                </g>
              </g>
              <g id="N">
                <g id="Bottom_Left_N">
                  <g id="N Inner">
                    <motion.path
                      id="Vector_11"
                      d="M438.515 129.977C438.515 80.8772 428.89 34.5271 358.091 34.5271C277.659 34.5271 277.659 119.253 277.659 129.977V258.664H275.136"
                      stroke="black"
                      strokeWidth="0.78533"
                      style={{ strokeDasharray: 417 }}
                      initial={{ strokeDashoffset: 417 }}
                      animate={{ strokeDashoffset: 417 * 2 }}
                    />
                  </g>
                  <g id="N Outer">
                    <motion.path
                      id="Vector_12"
                      d="M489.366 130.023C489.366 51.2153 456.205 1.33667 380.99 1.33667C358.091 1.33667 272.614 1.33668 272.614 97.2234V258.664H275.136"
                      stroke="black"
                      strokeWidth="0.78533"
                      style={{ strokeDasharray: 521 }}
                      initial={{ strokeDashoffset: 521 }}
                      animate={{ strokeDashoffset: 521 * 2 }}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </motion.svg>
      </MotionConfig>
    </div>
  );
};

export default Slide1;
