import { motion, MotionConfig } from "framer-motion";
import { useContext } from "react";
import ScreenWidthContext from "../ScreenWidthContext";

const Loading = () => {
  const { screenWidth } = useContext(ScreenWidthContext);

  return (
    <section
      style={{
        overflow: "hidden",
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <MotionConfig
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, repeat: Infinity }}
      >
        <div
          style={{
            height: "100%",
            padding: screenWidth <= 900 ? "35px" : `${88}px`,
            overflow: "hidden",
          }}
        >
          {/* Corner Arrows */}
          <motion.div
            className="corner-box-div"
            style={{
              position: "absolute",
              borderTop: "0px",
              borderLeft: "0px",
              top: screenWidth <= 900 ? "18.5px" : "44px",
              left: screenWidth <= 900 ? "18.5px" : "44px",
              width: screenWidth <= 900 ? "16.5px" : "44px",
              height: screenWidth <= 900 ? "16.5px" : "44px",
            }}
            initial={{
              opacity: 0,
            }}
          ></motion.div>
          <motion.div
            className="corner-box-div"
            style={{
              position: "absolute",
              borderTop: "0px",
              borderRight: "0px",
              top: screenWidth <= 900 ? "18.5px" : "44px",
              right: screenWidth <= 900 ? "18.5px" : "44px",
              width: screenWidth <= 900 ? "16.5px" : "44px",
              height: screenWidth <= 900 ? "16.5px" : "44px",
            }}
            initial={{
              opacity: 0,
            }}
          ></motion.div>
          <motion.div
            className="corner-box-div"
            style={{
              position: "absolute",
              borderBottom: "0px",
              borderLeft: "0px",
              bottom: screenWidth <= 900 ? "18.5px" : "44px",
              left: screenWidth <= 900 ? "18.5px" : "44px",
              width: screenWidth <= 900 ? "16.5px" : "44px",
              height: screenWidth <= 900 ? "16.5px" : "44px",
            }}
            initial={{
              opacity: 0,
            }}
          ></motion.div>
          <motion.div
            className="corner-box-div"
            style={{
              position: "absolute",
              borderBottom: "0px",
              borderRight: "0px",
              bottom: screenWidth <= 900 ? "18.5px" : "44px",
              right: screenWidth <= 900 ? "18.5px" : "44px",
              width: screenWidth <= 900 ? "16.5px" : "44px",
              height: screenWidth <= 900 ? "16.5px" : "44px",
            }}
            initial={{
              opacity: 0,
            }}
          ></motion.div>
        </div>
        <motion.div className="T-shaped-thing" initial={{ opacity: 0 }}>
          <motion.div
            className="T-shaped-thing-head"
            initial={{ width: 94.48 }}
          ></motion.div>
        </motion.div>
        <motion.div className="T-shaped-thing-side" initial={{ opacity: 0 }}>
          <motion.div
            className="T-shaped-thing-side-head"
            initial={{ opacity: 0, height: 95 }}
          ></motion.div>
        </motion.div>
        <motion.div
          className="T-shaped-thing-side"
          style={{
            left: "unset",
            right: "0",
            rotateZ: "180deg",
          }}
          initial={{ opacity: 0 }}
        >
          <motion.div
            className="T-shaped-thing-side-head"
            initial={{ height: 95 }}
          ></motion.div>
        </motion.div>
      </MotionConfig>
    </section>
  );
};

export default Loading;
