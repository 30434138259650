import Slide1 from "./Slide1";
import Slide2 from "./Slide2";
import Slide3 from "./Slide3";
import Slide4 from "./Slide4";
import Slide5 from "./Slide5";

import { memo } from "react";

import { AnimationProvider } from "../AnimationContext.js";

const Slides = ({ slideNo }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <AnimationProvider>
        {slideNo === 0 ? <Slide1 key={slideNo} /> : <></>}
      </AnimationProvider>
      {slideNo === 1 ? <Slide2 key={slideNo} /> : <></>}
      {slideNo === 2 ? <Slide3 key={slideNo} /> : <></>}
      {slideNo === 3 ? <Slide4 /> : <></>}
      <Slide5 visible={slideNo === 4} />
    </div>
  );
};
export default memo(Slides);
