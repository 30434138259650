import { useRef, useEffect, useState } from "react";
import useMeasure from "react-use-measure";
import { useVideoLoad } from "../useVideoLoad";

let FirstRender = true;
let dataUrl = "";

const pixelAnimationDuration = 0.4;
const videoLenght = 0.21;

const Slide4 = () => {
  const [onuLogoRef, onuLogoDim] = useMeasure();
  const [boxRef, boxDim] = useMeasure();

  const bannerVideoRef = useRef();
  const logoVideoRef = useRef();
  const animationRef = useRef();

  const percentageIncrease = 10;
  const widthIncrease = (boxDim.width * percentageIncrease) / 100;
  const heightIncrease = (boxDim.height * percentageIncrease) / 100;

  const [showPixelated, setShowPixelated] = useState(true);

  const randomPoint = (Math.random() * 12.6).toFixed(2);

  if (FirstRender) {
    dataUrl = useVideoLoad.getUrl("/Onuvideo3.mp4");
    FirstRender = false;
  }

  useEffect(() => {
    if (bannerVideoRef && logoVideoRef) {
      bannerVideoRef.current.currentTime = randomPoint;
      logoVideoRef.current.currentTime = randomPoint;
    }

    if (animationRef) {
      animationRef.current.addEventListener("endEvent", () => {
        setShowPixelated(false);
      });
    }
    bannerVideoRef.current.play();
    logoVideoRef.current.play();
  }, []);

  return (
    <div
      ref={boxRef}
      key={boxDim}
      style={{
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <svg style={{ width: "10%", height: "10%", position: "absolute" }}>
        <filter id="pixelate" x="0" y="0">
          <feFlood x={40} y={40} height={1} width={1} />
          <feComposite width={2} height={2}>
            <animate
              attributeName="width"
              from="40"
              to="2"
              dur={`${pixelAnimationDuration}`}
            />
            <animate
              attributeName="height"
              from="40"
              to="2"
              dur={`${pixelAnimationDuration}`}
            />
          </feComposite>
          <feTile result="a" />
          <feComposite in="SourceGraphic" in2="a" operator="in" />
          <feMorphology operator="dilate" radius="1">
            <animate
              ref={animationRef}
              attributeName="radius"
              from="20"
              to="1"
              dur={`${pixelAnimationDuration}s`}
            />
          </feMorphology>
        </filter>
      </svg>
      <video
        preload="auto"
        width="100%"
        height="100%"
        /* onPlay={() => { */
        /*   logoVideoRef.current.play(); */
        /* }} */
        ref={bannerVideoRef}
        muted
        loop
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          filter: showPixelated ? "url(#pixelate)" : "",
        }}
      >
        <source src={dataUrl} type="video/mp4" />
      </video>
      <div
        style={{
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <svg
          ref={onuLogoRef}
          width="656"
          height="260"
          viewBox="0 0 656 260"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            zIndex: "1",
            width: "41em",
            height: "16.25em",
          }}
        >
          <g id="ONU Logo" clipPath="url(#clip0_1_2)">
            <g id="ONU LOGO Final">
              <g id="O">
                <g id="Bottom_Left">
                  <path
                    id="Vector"
                    d="M128.91 258.663C104.49 258.663 82.3371 253.14 62.8617 242.079C43.606 231.245 27.7903 215.33 17.1888 196.12C6.21299 176.511 0.725098 154.634 0.725098 129.977"
                    stroke="white"
                  />
                  <path
                    id="Vector_2"
                    d="M128.91 253.684V253.669C48.8508 253.669 51.5676 196.534 51.5676 129.977"
                    stroke="white"
                  />
                </g>
                <g id="Top_Left">
                  <path
                    id="Vector_3"
                    d="M0.725098 129.977C0.725098 105.319 6.21299 83.144 17.1888 63.8717C27.7874 44.6598 43.6038 28.7441 62.8617 17.9122C82.3371 6.86664 104.49 1.33618 128.903 1.33618"
                    stroke="white"
                  />
                  <path
                    id="Vector_4"
                    d="M128.91 6.31506C51.3969 6.31506 51.5676 63.4197 51.5676 129.977"
                    stroke="white"
                  />
                </g>
                <g id="Bottom_Right">
                  <path
                    id="Vector_5"
                    d="M128.91 258.663C153.641 258.663 175.476 253.125 194.951 242.064C214.115 231.317 229.917 215.58 240.632 196.572C251.6 176.985 257.088 154.948 257.088 129.992"
                    stroke="white"
                  />
                  <path
                    id="Vector_6"
                    d="M206.245 130.007C206.245 196.587 208.962 253.684 128.91 253.684V253.669"
                    stroke="white"
                  />
                </g>
                <g id="Top_Right">
                  <path
                    id="Vector_7"
                    d="M128.903 1.33618C153.594 1.33618 175.476 6.86664 194.951 17.9275C214.254 28.4943 230.101 44.276 240.632 63.4198C251.6 83.0062 257.088 105.35 257.088 129.992"
                    stroke="white"
                  />
                  <path
                    id="Vector_8"
                    d="M206.245 130.007C206.245 63.4274 206.424 6.31506 128.91 6.31506"
                    stroke="white"
                  />
                </g>
              </g>
              <g id="Group 202">
                <g id="U">
                  <g id="U Outer">
                    <path
                      id="Vector_9"
                      d="M438.515 129.977C438.515 207.088 472.798 258.664 546.899 258.664C569.798 258.664 655.275 258.664 655.275 162.762V1.33606H652.753"
                      stroke="white"
                    />
                  </g>
                  <g id="U Inner">
                    <path
                      id="Vector_10"
                      d="M489.366 130.023C489.366 162.762 489.366 225.489 569.798 225.489C650.23 225.489 650.23 140.801 650.23 130.023V1.33606H652.753"
                      stroke="white"
                    />
                  </g>
                </g>
                <g id="N">
                  <g id="Bottom_Left_N">
                    <g id="N Innter">
                      <path
                        id="Vector_11"
                        d="M438.515 129.977C438.515 80.8772 428.89 34.5271 358.091 34.5271C277.659 34.5271 277.659 119.253 277.659 129.977V258.664H275.136"
                        stroke="white"
                      />
                    </g>
                    <path
                      id="Vector_12"
                      d="M489.366 130.023C489.366 51.2153 456.205 1.33667 380.99 1.33667C358.091 1.33667 272.614 1.33668 272.614 97.2234V258.664H275.136"
                      stroke="white"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <defs>
            <clipPath id="clip0_1_2">
              <rect width="656" height="260" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div
          style={{
            position: "absolute",
            width: `${onuLogoDim.width}px`,
            height: `${onuLogoDim.height}px`,
            overflow: "hidden",
          }}
          className="mask"
        >
          <video
            ref={logoVideoRef}
            key={boxDim}
            style={{
              width: `${boxDim.width + widthIncrease}px`,
              height: `${boxDim.height + heightIncrease}px`,
              objectFit: "cover",
              position: "absolute",
              top: `-${onuLogoDim.top - boxDim.top + heightIncrease / 2}px`,
              left: `-${onuLogoDim.left - boxDim.left + widthIncrease / 2}px`,
              filter: false ? "url(#pixelate)" : "",
            }}
            muted
            loop
          >
            <source src={dataUrl} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default Slide4;
