import { Suspense, useEffect, useContext } from "react";
import "./App.css";

import Homepage from "./components/Homepage";
import Loading from "./components/Loading";

import ScreenWidthContext from "./ScreenWidthContext";

function App() {
  const { setScreenWidth } = useContext(ScreenWidthContext);

  useEffect(() => {
    // setScreenWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setScreenWidth(window.innerWidth);
      });
    };
  }, []);
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Homepage />
      </Suspense>
    </>
  );
}

export default App;
