import { motion } from "framer-motion";

const Slide3 = () => {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundImage: "url('/dots.svg')",
          backgroundRepeat: "repeat",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <svg
          width="656"
          height="258"
          viewBox="0 0 656 258"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            width: "41em",
            height: "16.25em",
          }}
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M128.91 257.663C104.49 257.663 82.3371 252.14 62.8617 241.08C43.606 230.245 27.7903 214.33 17.1888 195.12C6.21299 175.511 0.725098 153.634 0.725098 128.977C0.725098 104.319 6.21299 82.144 17.1888 62.8717C27.7874 43.6598 43.6038 27.7441 62.8617 16.9122C82.3371 5.86664 104.49 0.336182 128.903 0.336182C153.594 0.336182 175.476 5.86664 194.951 16.9275C214.254 27.4943 230.101 43.276 240.632 62.4198C251.6 82.0062 257.088 104.35 257.088 128.992C257.088 153.948 251.6 175.986 240.632 195.572C229.917 214.58 214.115 230.317 194.951 241.064C175.476 252.125 153.641 257.663 128.91 257.663ZM128.91 252.684C208.962 252.684 206.245 195.587 206.245 129.007C206.245 62.4274 206.424 5.31512 128.91 5.31512C51.3969 5.31512 51.5676 62.4198 51.5676 128.977C51.5676 195.534 48.8509 252.669 128.91 252.669V252.684Z"
            fill="white"
          />
          <path
            d="M438.515 128.977C438.515 79.8772 428.89 33.527 358.091 33.527C277.659 33.527 277.659 118.253 277.659 128.977V257.664H272.614V96.2234C272.614 0.336639 358.091 0.336633 380.99 0.336633C456.205 0.336633 489.366 50.2153 489.366 129.023C489.366 161.762 489.366 224.489 569.798 224.489C650.23 224.489 650.23 139.801 650.23 129.023V0.33606H655.275V161.762C655.275 257.664 569.798 257.664 546.899 257.664C472.798 257.664 438.515 206.088 438.515 128.977Z"
            fill="white"
          />
        </svg>

        <motion.div
          initial={{ x: 0, y: "0%" }}
          animate={{ x: "100%", y: "0%" }}
          transition={{
            type: "linear",
            duration: "0.4",
          }}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "white",
          }}
        ></motion.div>
        <motion.div
          initial={{ x: 50, y: "0%" }}
          animate={{ x: "-100%", y: "0%" }}
          transition={{
            type: "linear",
            duration: "0.4",
          }}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "white",
          }}
        ></motion.div>
      </div>
    </>
  );
};

export default Slide3;
