import Slides from "./Slides";
import { motion, MotionConfig } from "framer-motion";
import { useRef, useState, useContext, memo } from "react";
import ScreenWidthContext from "../ScreenWidthContext";
import Mailicon from "./Mailicon";
import { useVideoLoad } from "../useVideoLoad";

const aniamtedTextDuration = 0.1;
const flashKeyFrame = [0, 1, 1, 1, 1, 0, 1, 1, 0, 1];
const flashDuration = 0.4;
const flashDelayOverAll = 0.4;
const flashDelay = 1.0;

let prev = 0;
let current = 0;

let listnerPresent = false;

const date = new Date()
  .toLocaleDateString("en-in", {
    day: "2-digit",
    month: "long",
    year: "2-digit",
  })
  .split(" ");

const Homepage = () => {
  const { screenWidth } = useContext(ScreenWidthContext);
  useVideoLoad("/Onuvideo3.mp4");

  const iconRefs = [useRef(), useRef(), useRef()];

  const TextLine1 = "We are currently under construction";
  const TextLine2 = "please stick around";

  const [slideNo, setSlideNo] = useState(0);
  const slides = [1, 2, 3, 4];
  const slideNoRef = useRef(slideNo);

  const textContainerRef = useRef();

  const getRandIndex = () => {
    current = Math.floor(Math.random() * 4);
    while (current === prev) {
      current = Math.floor(Math.random() * 4);
    }
    prev = current;
    return current;
  };

  const addTextEventListner = () => {
    if (textContainerRef) {
      if (!listnerPresent) {
        listnerPresent = true;
        textContainerRef.current.addEventListener("touchend", () => {
          let index = getRandIndex();
          setSlideNo(slides[index]);
          // setSlideNo(2);
          slideNoRef.current = slides[index];
          for (let i = 0; i < 3; i++) {
            if (
              iconRefs[i] &&
              slideNoRef.current !== 4 &&
              slideNoRef.current !== 2
            )
              iconRefs[i].current.style.stroke = "white";
            else iconRefs[i].current.style.stroke = "black";
          }
          // setSlideNo(1);
        });
        textContainerRef.current.addEventListener("mouseenter", () => {
          let index = getRandIndex();
          setSlideNo(slides[index]);
          // setSlideNo(2);
          slideNoRef.current = slides[index];
          for (let i = 0; i < 3; i++) {
            if (
              iconRefs[i] &&
              slideNoRef.current !== 4 &&
              slideNoRef.current !== 2
            )
              iconRefs[i].current.style.stroke = "white";
          }
          // setSlideNo(1);
        });
        textContainerRef.current.addEventListener("mouseleave", () => {
          setSlideNo(0);
          for (let i = 0; i < 3; i++) {
            if (iconRefs[i]) iconRefs[i].current.style.stroke = "black";
          }
        });
      }
    }
  };

  return (
    <section
      style={{
        overflow: "hidden",
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <div
        key={screenWidth}
        style={{
          height: "100%",
          padding: screenWidth <= 900 ? "35px" : `${88}px`,
          overflow: "hidden",
        }}
      >
        {" "}
        {/* Corner Arrows */}{" "}
        <motion.div
          className="corner-box-div"
          style={{
            position: "absolute",
            borderTop: "0px",
            borderLeft: "0px",
          }}
          initial={{
            top: screenWidth <= 900 ? "9.5px" : "18px",
            left: screenWidth <= 900 ? "9.5px" : "18px",
            width: screenWidth <= 900 ? "25.5px" : "70px",
            height: screenWidth <= 900 ? "25.5px" : "70px",
            opacity: 0,
          }}
          animate={{
            opacity: flashKeyFrame,
            top: screenWidth <= 900 ? "18.5px" : "44px",
            left: screenWidth <= 900 ? "18.5px" : "44px",
            width: screenWidth <= 900 ? "16.5px" : "44px",
            height: screenWidth <= 900 ? "16.5px" : "44px",
          }}
          transition={{
            opacity: { delay: flashDelayOverAll, duration: flashDuration },
            top: { delay: flashDelay },
            left: { delay: flashDelay },
            width: { delay: flashDelay },
            height: { delay: flashDelay },
          }}
        ></motion.div>
        <motion.div
          className="corner-box-div"
          style={{
            position: "absolute",
            borderTop: "0px",
            borderRight: "0px",
          }}
          initial={{
            top: screenWidth <= 900 ? "9.5px" : "18px",
            right: screenWidth <= 900 ? "9.5px" : "18px",
            width: screenWidth <= 900 ? "25.5px" : "70px",
            height: screenWidth <= 900 ? "25.5px" : "70px",
            opacity: 0,
          }}
          animate={{
            opacity: flashKeyFrame,
            top: screenWidth <= 900 ? "18.5px" : "44px",
            right: screenWidth <= 900 ? "18.5px" : "44px",
            width: screenWidth <= 900 ? "16.5px" : "44px",
            height: screenWidth <= 900 ? "16.5px" : "44px",
          }}
          transition={{
            opacity: { delay: flashDelayOverAll, duration: flashDuration },
            top: { delay: flashDelay },
            right: { delay: flashDelay },
            width: { delay: flashDelay },
            height: { delay: flashDelay },
          }}
        ></motion.div>
        <motion.div
          className="corner-box-div"
          style={{
            position: "absolute",
            borderBottom: "0px",
            borderLeft: "0px",
          }}
          initial={{
            bottom: screenWidth <= 900 ? "9.5px" : "18px",
            left: screenWidth <= 900 ? "9.5px" : "18px",
            width: screenWidth <= 900 ? "25.5px" : "70px",
            height: screenWidth <= 900 ? "25.5px" : "70px",
            opacity: 0,
          }}
          animate={{
            opacity: flashKeyFrame,
            bottom: screenWidth <= 900 ? "18.5px" : "44px",
            left: screenWidth <= 900 ? "18.5px" : "44px",
            width: screenWidth <= 900 ? "16.5px" : "44px",
            height: screenWidth <= 900 ? "16.5px" : "44px",
          }}
          transition={{
            opacity: { delay: flashDelayOverAll, duration: flashDuration },
            bottom: { delay: flashDelay },
            left: { delay: flashDelay },
            width: { delay: flashDelay },
            height: { delay: flashDelay },
          }}
        ></motion.div>
        <motion.div
          className="corner-box-div"
          style={{
            position: "absolute",
            borderBottom: "0px",
            borderRight: "0px",
          }}
          initial={{
            bottom: screenWidth <= 900 ? "9.5px" : "18px",
            right: screenWidth <= 900 ? "9.5px" : "18px",
            width: screenWidth <= 900 ? "25.5px" : "70px",
            height: screenWidth <= 900 ? "25.5px" : "70px",
            opacity: 0,
          }}
          animate={{
            opacity: flashKeyFrame,
            bottom: screenWidth <= 900 ? "18.5px" : "44px",
            right: screenWidth <= 900 ? "18.5px" : "44px",
            width: screenWidth <= 900 ? "16.5px" : "44px",
            height: screenWidth <= 900 ? "16.5px" : "44px",
          }}
          transition={{
            opacity: { delay: flashDelayOverAll, duration: flashDuration },
            bottom: { delay: flashDelay },
            right: { delay: flashDelay },
            width: { delay: flashDelay },
            height: { delay: flashDelay },
          }}
        ></motion.div>
        {/* End of Corner Arrows */}
        {/* Actual Rendering Area */}
        <div
          style={{
            position: "relative",
            height: "100%",
          }}
        >
          <MotionConfig
            transition={{
              delay: screenWidth <= 900 ? 2.1 : 2.8,
              opacity: { duration: 0.1 },
            }}
          >
            <Mailicon ref={iconRefs[0]} backgroundVisible={slideNo === 2} />
            <motion.div
              onAnimationComplete={(d) => {
                addTextEventListner();
              }}
              initial={{
                opacity: 0,
                right: screenWidth <= 900 ? `50%` : "63.63px",
                bottom: screenWidth <= 900 ? `46px` : `50%`,
              }}
              animate={{
                opacity: 1,
                right: screenWidth <= 900 ? `50%` : "43.63px",
                bottom: screenWidth <= 900 ? `26px` : `50%`,
              }}
              style={{
                backgroundColor: slideNo === 2 ? "white" : "transparent",
                position: "absolute",
                transform:
                  screenWidth <= 900 ? "translateX(50%)" : "translateY(50%)",
                zIndex: 5,
              }}
            >
              <svg
                ref={iconRefs[1]}
                xmlns="http://www.w3.org/2000/svg"
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                style={{
                  width: screenWidth <= 400 ? "5.385em" : "3.385em",
                  height: screenWidth <= 400 ? "5.385em" : "3.385em",
                  bottom: screenWidth <= 900 ? "26px" : "50%",
                  right: screenWidth <= 900 ? "50%" : "43.63px",
                  stroke: "black",
                  pointerEvents: "none",
                }}
              >
                <circle cx="26.9644" cy="27" r="25" strokeWidth="3" />
                <path
                  d="M25.5622 15.1001H24.0622V16.6001V17.2985C19.2251 18.5891 15.6488 23.01 15.2169 28.304L12.9847 29.0832L11.9791 29.4342V30.4994V32.5596V34.0596H13.4791H40.4498H41.9498V32.5596V30.4994V29.4338L40.9437 29.083L38.7083 28.3036C38.2764 23.0129 34.7042 18.5944 29.8716 17.3008V16.6001V15.1001H28.3716H25.5622Z"
                  strokeWidth="3"
                />
                <path
                  d="M23.9677 17.8175C23.9677 20.4821 23.9677 23.6237 23.9677 23.6237M29.9612 17.8175V23.6237"
                  strokeWidth="3"
                />
                <path d="M19.0511 28.9074H34.8777" strokeWidth="3" />
              </svg>
            </motion.div>
            <motion.div
              initial={{
                opacity: 0,
                left: screenWidth <= 900 ? "50%" : "63.63px",
                top: screenWidth <= 900 ? "46px" : "50%",
              }}
              animate={{
                opacity: 1,
                left: screenWidth <= 900 ? "50%" : "43.63px",
                top: screenWidth <= 900 ? "26px" : "50%",
              }}
              style={{
                backgroundColor: slideNo === 2 ? "white" : "transparent",
                position: "absolute",
                top: screenWidth <= 900 ? "26px" : "50%",
                left: screenWidth <= 900 ? "50%" : "43.63px",
                transform:
                  screenWidth <= 900 ? "translateX(-50%)" : "translateY(-50%)",
                zIndex: 5,
              }}
            >
              <svg
                ref={iconRefs[2]}
                xmlns="http://www.w3.org/2000/svg"
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                style={{
                  width: screenWidth <= 400 ? "5.385em" : "3.385em",
                  height: screenWidth <= 400 ? "5.385em" : "3.385em",
                  stroke: "black",
                  pointerEvents: "none",
                }}
              >
                <circle cx="27.4091" cy="27" r="25" strokeWidth="3" />
                <path
                  d="M21.8034 21.9229L26.207 17.5193L21.6174 12.9298L12.8102 21.7369L17.3998 26.3265L21.8034 21.9229ZM21.8034 21.9229L37.6045 37.724"
                  strokeWidth="3"
                />
                <path
                  d="M34.6661 13.2225L33.3989 14.4897C31.4523 16.4363 31.4523 19.5923 33.3989 21.5389V21.5389M41.7154 20.2717L40.4482 21.5389C38.5016 23.4855 35.3455 23.4855 33.3989 21.5389V21.5389M33.3989 21.5389L21.5969 33.341"
                  strokeWidth="3"
                />
                <path
                  d="M20.1522 41.835L21.4194 40.5678C23.366 38.6212 23.366 35.4651 21.4194 33.5185V33.5185V33.5185C19.4728 31.5719 16.3167 31.5719 14.3701 33.5185L13.1029 34.7857"
                  strokeWidth="3"
                />
              </svg>
            </motion.div>
          </MotionConfig>
          <Slides slideNo={slideNo} />
        </div>
      </div>
      <div
        className="animated-text-container"
        id="animated-text-container"
        ref={textContainerRef}
        style={{
          height: `${21.99}em`,
          width: screenWidth <= 400 ? "70.4em" : "56em",
          zIndex: "4",
          userSelect: "none",
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <h1
          style={{
            fontSize: "2.16em",
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            fontWeight: "600",
            width: "100%",
            pointerEvents: "none",
          }}
        >
          <MotionConfig transition={{ delay: 2, duration: 0.1 }}>
            <motion.div
              style={{
                display: "block",
                backgroundColor: "white",
                padding: "0 13px",
                width: "fit-content",
                marginRight: "auto",
                marginLeft: "auto",
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              {TextLine1.split("").map((char, index) => {
                return (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: aniamtedTextDuration,
                      delay: 1.76 + Math.random() * (0.3 - 0.1) + 0.3,
                    }}
                    key={index}
                    style={{ verticalAlign: "text-top" }}
                  >
                    {char}
                  </motion.span>
                );
              })}
            </motion.div>
            <motion.div
              style={{
                backgroundColor: "white",
                padding: "0 13px",
                width: "fit-content",
                marginRight: "auto",
                marginLeft: "auto",
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              {TextLine2.split("").map((char, index) => {
                return (
                  <motion.span
                    style={{
                      verticalAlign: "text-top",
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: aniamtedTextDuration,
                      delay: 1.76 + Math.random() * (0.3 - 0.1) + 0.3,
                    }}
                    key={index}
                  >
                    {char}
                  </motion.span>
                );
              })}
            </motion.div>
          </MotionConfig>
        </h1>
      </div>
      <motion.div
        className="T-shaped-thing"
        initial={{ opacity: 0 }}
        animate={{ opacity: flashKeyFrame }}
        transition={{
          opacity: { delay: flashDelayOverAll, duration: flashDuration },
        }}
      >
        <motion.div
          className="T-shaped-thing-head"
          initial={{ width: window.innerWidth / 2 }}
          animate={{ width: 94.48 }}
          transition={{ delay: flashDelay }}
        ></motion.div>
      </motion.div>
      <motion.div
        className="T-shaped-thing-side"
        initial={{ opacity: 0 }}
        animate={{ opacity: flashKeyFrame }}
        transition={{
          opacity: { delay: flashDelayOverAll, duration: flashDuration },
        }}
      >
        <motion.div
          className="T-shaped-thing-side-head"
          initial={{ height: window.innerHeight / 2 - 87 }}
          animate={{ height: 95 }}
          transition={{ delay: flashDelay }}
        ></motion.div>
      </motion.div>
      <motion.div
        className="T-shaped-thing-side"
        style={{
          left: "unset",
          right: "0",
          rotateZ: "180deg",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: flashKeyFrame }}
        transition={{
          opacity: { delay: flashDelayOverAll, duration: flashDuration },
        }}
      >
        <motion.div
          className="T-shaped-thing-side-head"
          initial={{ height: (window.innerHeight - 87) / 2 }}
          animate={{ height: 95 }}
          transition={{ delay: flashDelay }}
        ></motion.div>
      </motion.div>
      <motion.div
        style={{
          position: "absolute",
          bottom: "0",
          left: "0",
          width: "100%",
          height: screenWidth <= 900 ? "6px" : "12px",
          display: "flex",
          padding: screenWidth <= 900 ? "0 16.5px" : "0 43px",
        }}
        initial={{ opacity: 1 }}
        animate={{ opacity: flashKeyFrame }}
        transition={{ opacity: { duration: flashDuration } }}
      >
        <div style={{ width: "5%", backgroundColor: "#9F9188" }}></div>
        <div style={{ width: "10%", backgroundColor: "#FFF202" }}></div>
        <div style={{ width: "15%", backgroundColor: "#019BDD" }}></div>
        <div style={{ width: "40%", backgroundColor: "#EF4D89" }}></div>
        <div style={{ width: "30%", backgroundColor: "#1B1E1F" }}></div>
      </motion.div>
      <div
        style={{
          position: "absolute",
          top: "44px",
          left: "0",
          width: "100vw",
          height: screenWidth <= 900 ? "6px" : "12px",
          display: screenWidth <= 900 ? "none" : "flex",
          padding: screenWidth <= 900 ? "0 16.5px" : "0 43px",
          fontSize: "1.25em",
          fontFamily: "Manrope",
          fontWeight: "600",
        }}
      >
        <div style={{ width: "5%" }}></div>
        <MotionConfig animate={{ opacity: 1 }} transition={{ duration: 0.1 }}>
          <div
            style={{
              width: "10%",
              textAlign: "right",
            }}
          >
            {date[0].split("").map((char, index) => {
              return (
                <motion.span
                  initial={{ opacity: 0 }}
                  transition={{
                    delay: 2.1 + Math.random() * (0.3 - 0.1) + 0.3,
                  }}
                  key={index}
                >
                  {char}
                </motion.span>
              );
            })}
          </div>
          <div
            style={{
              width: "15%",
              textAlign: "right",
            }}
          >
            {date[1].split("").map((char, index) => {
              return (
                <motion.span
                  initial={{ opacity: 0 }}
                  transition={{
                    delay: 2.2 + Math.random() * (0.3 - 0.1) + 0.3,
                  }}
                  key={index}
                >
                  {char}
                </motion.span>
              );
            })}
          </div>
          <div
            style={{
              width: "40%",
              textAlign: "right",
            }}
          >
            {date[2].split().map((char, index) => {
              return (
                <motion.span
                  initial={{ opacity: 0 }}
                  transition={{
                    delay: 2.3 + Math.random() * (0.3 - 0.1) + 0.3,
                  }}
                  key={index}
                >
                  {char}
                </motion.span>
              );
            })}
          </div>
        </MotionConfig>
        <div style={{ width: "30%" }}></div>
      </div>
    </section>
  );
};

export default memo(Homepage);
