import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";

let cache = {};

let calledTable = {};
export function useVideoLoad(url, callback = () => {}) {
  if (calledTable[url] === "complete") return calledTable[url].status;

  const promise = useVideoLoad.preload(url);

  calledTable[url] = "pending";

  promise.then(() => {
    calledTable[url] = "complete";
  });

  throw promise;
}

useVideoLoad.preload = async (url) => {
  if (typeof caches !== "undefined") {
    const newCache = await caches.open("ONU");
    const data = await newCache.match(url);

    if (data) {
      const blob = await data.blob();
      cache[`${url}`] = URL.createObjectURL(blob);
    } else {
      await newCache.add(url);
      const newData = await newCache.match(url);
      const blob = await newData.blob();
      cache[url] = URL.createObjectURL(blob);
    }
  } else {
    const data = await fetch(url);
    const blob = await data.blob();
    cache[url] = URL.createObjectURL(blob);
  }
  await useLoader.preload(GLTFLoader, "./OnuLogo3DObj-transformed.glb");
  await useLoader.preload(RGBELoader, "./ONU HDR.hdr");
};

useVideoLoad.getUrl = (url) => {
  return cache[url];
};
