import { createContext, useState } from "react";

const ScreenWidthContext = createContext();

export function ScreenWidthProvider({ children }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  return (
    <ScreenWidthContext.Provider value={{ screenWidth, setScreenWidth }}>
      {children}
    </ScreenWidthContext.Provider>
  );
}

export default ScreenWidthContext;
