import { Canvas } from "@react-three/fiber";
import { Environment, Preload, useGLTF, AdaptiveDpr } from "@react-three/drei";
import { useEffect, useRef, forwardRef, useContext, Suspense } from "react";
import { MathUtils } from "three";
import ScreenWidthContext from "../ScreenWidthContext";

const OnuLogo3D = forwardRef((props, ref) => {
  const { nodes, materials } = useGLTF("./OnuLogo3DObj-transformed.glb");
  return (
    <group visible={props.visible} ref={ref} {...props} dispose={null}>
      <mesh
        geometry={nodes.onur_logo001.geometry}
        material={materials.Chromemat}
        position={[-8.885, -8, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
    </group>
  );
});

const Slide5 = ({ visible }) => {
  const { screenWidth } = useContext(ScreenWidthContext);
  const modelRef = useRef();

  const moveModel = (event) => {
    if (modelRef.current) {
      modelRef.current.rotation.y = MathUtils.lerp(
        -0.5,
        0.5,
        event.clientX / screenWidth,
      );
    }
  };

  const moveModelTouch = (event) => {
    const tounchLocation = event.touches[0];
    if (modelRef.current) {
      modelRef.current.rotation.y = MathUtils.lerp(
        -0.5,
        0.5,
        tounchLocation.pageX / screenWidth,
      );
    }
  };

  useEffect(() => {
    const aniDiv = document.getElementById("animated-text-container");
    aniDiv.addEventListener("mousemove", moveModel);

    aniDiv.addEventListener("touchmove", moveModelTouch);
    return () => {
      aniDiv.removeEventListener("mousemove", moveModel);
      aniDiv.removeEventListener("touchmove", moveModelTouch);
    };
  }, []);

  return (
    <Suspense fallback={<div></div>}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "45.9em",
            height: "17.3em",
          }}
          id="ONU Logo"
        >
          <Canvas
            key={screenWidth}
            id="ONU Logo"
            orthographic
            camera={{
              zoom: screenWidth * (screenWidth <= 900 ? 4.5 / 100 : 2.2 / 100),
            }}
          >
            <Preload all />
            <AdaptiveDpr pixelated />
            <Suspense fallback={null}>
              <group visible={visible}>
                <Environment files="./ONU HDR.hdr" />
              </group>
            </Suspense>
            <OnuLogo3D visible={visible} ref={modelRef} />
            <pointLight intensity={5} position={[0, -4, 0]} />
            <ambientLight intensity={1.4} />
          </Canvas>
        </div>
      </div>
    </Suspense>
  );
};

export default Slide5;
